import { Company } from 'interfaces/Company'
import { Optional } from 'shared/util/types.util'
import UserContextCompanyUserRole from 'shared/models/UserContextCompanyUserRole.model'
import { compact, join } from 'lodash'

export enum UserType {
  USER = 'User',
  DRIVER = 'Driver',
  BOT = 'Bot',
  SYSTEM = 'System',
}

export interface BaseUser {
  id: number
  email: string
  lastName: string
  firstName: string
  jobTitle?: string
  department?: string
  isClaimed?: boolean
  contactPhone?: string
  avatarUrl?: string
  company?: Company
  locale?: string
}

export interface User extends BaseUser {
  companyUserRoles: UserContextCompanyUserRole[]
  advicesCount?: number
}

export interface InvitedUser extends BaseUser {
  matchesCompany?: boolean
  onboarded?: boolean
}

export type AuthenticationMethod = 'session' | 'token' | string

export interface AuthenticatedUser extends User {
  companyUserRoles: UserContextCompanyUserRole[]
  passwordPresent: boolean
  invitationAccepted: boolean
  authenticationMethod: AuthenticationMethod
  onboarded?: true
}

export interface TokenUser extends AuthenticatedUser {
  authenticationMethod: 'token'
}

export interface SessionUser extends AuthenticatedUser {
  authenticationMethod: 'session'
}

export const isTokenUser = (user: AuthenticatedUser): user is TokenUser => (
  user.authenticationMethod === 'token'
)

export const isSessionUser = (user: Optional<AuthenticatedUser>): user is SessionUser => (
  user?.authenticationMethod === 'session'
)

export const userToLabel = (user: BaseUser): string => {
  const { firstName, lastName, jobTitle, department } = user
  const userName = join(compact([firstName, lastName]), ' ')
  const userPosition = join(compact([jobTitle, department]), ', ')
  return `${userName}${userPosition ? ` (${userPosition})` : ''}`
}
export const userToId = (user: BaseUser): string => user.id.toString() ?? ''
